import { Await, useFetcher, useRouteLoaderData } from "@remix-run/react";
import { Suspense, useState } from "react";
import { Modal } from "react-daisyui";
import { createPortal } from "react-dom";
import { ClientOnly } from "remix-utils/client-only";
import { useHydrated } from "remix-utils/use-hydrated";

import LoadingOverlay from "../LoadingOverlay";

import QuillRenderHtml from "~/components/atoms/QuillRenderHtml";
import Typo from "~/components/atoms/Typo";
import { TLoaderData } from "~/root";
import { TSiteContentLoaderData } from "~/routes/($lang).$siteContent._index/route";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { LoaderSlug } from "~/utilities/enums/Slug";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface PrivacyModalProps {
  children: React.ReactNode;
  disabled?: boolean;
}

export default function PrivacyModal({
  disabled,
  children,
}: PrivacyModalProps) {
  const fetcher = useFetcher<TSiteContentLoaderData>();
  const rootData = useRouteLoaderData<TLoaderData>("root");

  const isClient = useHydrated();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    fetcher.load(
      `${LoaderSlug.SITE_CONTENT}?siteContentType=privacy-policy&originalDomain=${rootData?.domain}`
    );

    setIsOpen(true);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          handleOpenModal();
        }}
        disabled={disabled}
      >
        {children}
      </button>

      <ClientOnly fallback={<></>}>
        {() =>
          createPortal(
            <Modal.Legacy
              className={cn(
                "mx-10 h-full w-[95%] max-w-[1280px] p-10 lg:max-h-[90svh]"
              )}
              open={isOpen}
              onClickBackdrop={() => {
                setIsOpen(false);
              }}
            >
              <Modal.Body>
                <Suspense
                  fallback={
                    <div className="h-full w-full">
                      <LoadingOverlay isLoading />
                    </div>
                  }
                >
                  <Await
                    resolve={fetcher?.data}
                    errorElement={<div>Error loading content</div>}
                  >
                    {(resolvedData: TSiteContentLoaderData | undefined) => (
                      <div className="ql-snow  flex flex-col gap-10 lg:gap-8 lg:py-0">
                        <Typo
                          tag={ETypoTag.H2}
                          variant={ETypoVariant.HEADER_36}
                          color={ETypoColor.TEXT}
                          className="border-b border-gableGreen25 pb-10 font-bold lg:pb-8"
                        >
                          {resolvedData?.title}
                        </Typo>
                        <QuillRenderHtml html={resolvedData?.content || ""} />
                      </div>
                    )}
                  </Await>
                </Suspense>
              </Modal.Body>
            </Modal.Legacy>,
            document.body
          )
        }
      </ClientOnly>
    </>
  );
}
