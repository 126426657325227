import { useTranslation } from "react-i18next";

import CleanedContent from "~/components/atoms/CleanedContent";
import MasonryGalleryLDP from "~/components/molecules/MasonryGalleryLDP";
import SectionProject from "~/components/templates/SectionProject";
import { TMedia } from "~/entities/media";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";

export interface SectionProjectMasonryGalleryProps {
  className?: string;
  id: string;
  title: string;
  project: IProject;
  description?: string;
  medias?: TMedia[];
}

const SectionProjectMasonryGalleryLDP: React.FC<
  SectionProjectMasonryGalleryProps
> = ({
  className = "",
  id,
  title,
  project,
  medias = project?.medias || [],
}) => {
  const { t } = useTranslation();

  if (!project) return <></>;
  return (
    <SectionProject
      id={id}
      className={cn(
        "relative flex flex-col gap-y-12 overflow-hidden py-20 lg:px-4 lg:py-7",
        className
      )}
    >
      <CleanedContent
        className="!text-header-24 font-bold text-color lg:!text-header-20"
        htmlContent={title}
      />

      <MasonryGalleryLDP project={project} medias={medias} />
    </SectionProject>
  );
};

export default SectionProjectMasonryGalleryLDP;
