import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useMemo, useRef, useState } from "react";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";

import CleanedContent from "~/components/atoms/CleanedContent";
import { Icon } from "~/components/atoms/Icons";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import Video from "~/components/atoms/Video";
import ModalMediaGallery from "~/components/organisms/ModalMediaGallery";
import { TMedia } from "~/entities/media";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { EMedia } from "~/utilities/enums/Media";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface MastheadGalleryProps {
  project: IProject;
  onShowPriceList: () => void;
  onShowFlat: () => void;
  className?: string;
  isShowPriceList?: boolean;
  isShowFlat?: boolean;
  isShowColtrols?: boolean;
  isShowProjectName?: boolean;
  size?: string;
  showGradientLayer?: boolean;
  titleHtml?: string;
  subTitleHtml?: string;
  styleRoot?: Record<string, string>;
  galleryMedias?: TMedia[];
}

const MastheadGalleryLDP: React.FC<MastheadGalleryProps> = ({
  project,
  className = "",
  isShowColtrols = true,
  isShowProjectName = true,
  size = "large",
  showGradientLayer,
  titleHtml,
  subTitleHtml,
  styleRoot,
  galleryMedias = project?.medias || [],
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<SwiperRef>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [indexSlides, setIndexSlides] = useState(0);

  const { countMedia } = useMemo(() => {
    if (!isArray(galleryMedias) || isEmpty(galleryMedias))
      return { countMedia: 0 };
    const countMedia = galleryMedias.length;
    return { countMedia };
  }, [galleryMedias]);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setIndexSlides(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  return (
    <>
      <div
        style={styleRoot}
        className={cn(
          "relative h-[584px] max-h-screen w-full overflow-hidden lg:h-[560px]",
          className
        )}
      >
        <Swiper
          className="h-full w-full"
          slidesPerView={1}
          spaceBetween={0}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          modules={[Autoplay, EffectFade]}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          ref={swiperRef}
        >
          {galleryMedias.map((media: TMedia, index: number) => (
            <SwiperSlide
              key={media.id + index}
              onClick={() => handleOpenModal(index)}
            >
              {showGradientLayer && (
                <div className="absolute z-10 h-full w-full bg-ldp-masthead-gradient" />
              )}
              {media.type === EMedia.IMAGE ? (
                <ImageResponsive
                  className="h-full w-full object-cover"
                  imageData={media}
                />
              ) : (
                <Video
                  videoData={media}
                  autoPlay={activeIndex === index}
                  className="h-full w-full"
                  iframeClass="w-full h-full"
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        {isShowProjectName && project?.name && (
          <div className="absolute left-1/2 top-0 z-50 my-12 -translate-x-1/2 transform text-center">
            <CleanedContent
              className="text-center !text-header-68 text-[var(--main-color)] lg:!text-header-40"
              htmlContent={titleHtml || project?.name}
            />

            {(subTitleHtml || project?.location?.name) && (
              <CleanedContent
                className="text-center !text-header-24 text-[var(--main-color)]"
                htmlContent={subTitleHtml || project?.location?.name || ""}
              />
            )}
          </div>
        )}
        <div className="absolute left-0 top-0 h-full w-full">
          <div className="ldp relative h-full">
            {!!galleryMedias?.length && isShowColtrols && (
              <>
                <button
                  className="absolute left-4 top-1/2 z-50 -translate-y-1/2 rounded-full bg-color p-3 shadow-md transition-transform hover:scale-105 active:scale-95"
                  onClick={(e) => {
                    e.stopPropagation();
                    swiperRef?.current?.swiper?.slidePrev?.();
                  }}
                >
                  <Icon
                    TypeIcon={EIcon.ArrowLeftIcon}
                    height={40}
                    width={20}
                    className="text-backgroundPageProject"
                  />
                </button>
                <button
                  className="absolute right-4 top-1/2 z-50 -translate-y-1/2 rounded-full bg-color p-3 shadow-md transition-transform hover:scale-105 active:scale-95"
                  onClick={(e) => {
                    e.stopPropagation();
                    swiperRef?.current?.swiper?.slideNext?.();
                  }}
                >
                  <Icon
                    TypeIcon={EIcon.ArrowRightIcon}
                    height={40}
                    width={20}
                    className="text-backgroundPageProject"
                  />
                </button>
              </>
            )}
            {countMedia && Number(countMedia) > 0 && (
              <div className="absolute bottom-4 left-4 z-50  rounded-xl bg-color px-3 py-2 opacity-75">
                <Typo
                  tag={ETypoTag.SPAN}
                  variant={ETypoVariant.SUB_TITLE_14}
                  color={ETypoColor.WHITE}
                >
                  {`${(activeIndex + 1)
                    .toString()
                    .padStart(2, "0")}/${countMedia
                    .toString()
                    .padStart(2, "0")}`}
                </Typo>
              </div>
            )}
          </div>
        </div>
      </div>

      {isOpen && (
        <ModalMediaGallery
          medias={galleryMedias}
          closeModal={handleCloseModal}
          isOpen={isOpen}
          indexSlide={indexSlides}
        />
      )}
    </>
  );
};

export default MastheadGalleryLDP;
