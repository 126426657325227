import isEmpty from "lodash/isEmpty";
import { ReactNode, useState } from "react";

import Typo from "~/components/atoms/Typo";
import VirtualCardLDP from "~/components/molecules/VirtualCardLDP";
import VirtualView from "~/components/molecules/VirtualView";
import { IVirtualTour } from "~/entities/virtualTour";
import { useModal } from "~/hooks/use-modal";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Slug } from "~/utilities/enums/Slug";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface ProjectVirtualTourLDPMobileProps {
  title: string;
  description?: string;
  select?: ReactNode;
  paddingClass?: string;
  className?: string;
  thumbnailClass?: string;
  virtualTours: IVirtualTour[];
}

const ProjectVirtualTourLDPMobile: React.FC<
  ProjectVirtualTourLDPMobileProps
> = (props) => {
  const {
    title,
    description,
    select,
    paddingClass = "",
    className,
    thumbnailClass = "",
    virtualTours,
  } = props;
  const [iframe, setIframe] = useState<string | undefined>(undefined);

  const { modal, openModal, closeModal } = useModal({
    children: <VirtualView iframe={iframe} onClick={() => closeModal()} />,
    allowBackdropClick: true,
    className:
      "w-full h-full max-w-full max-h-full border-none rounded-none bg-black p-0",
  });

  return (
    <div
      className={cn(
        "ldp flex w-full flex-col gap-6 rounded-xl px-0 py-6 lg:gap-5 lg:rounded-lg lg:px-6 lg:py-0",
        paddingClass,
        className
      )}
    >
      <div className=" flex items-end justify-between gap-x-6 lg:mb-3.75 lg:flex-col lg:items-start lg:gap-y-5">
        <div className="flex w-1/2 flex-col gap-y-2 lg:w-full">
          <Typo
            tag={ETypoTag.H2}
            variant={ETypoVariant.HEADER_PROJECT_24}
            color={ETypoColor.PROJECT_HEADING}
            className="font-bold"
          >
            {title}
          </Typo>
          <Typo tag={ETypoTag.SPAN} variant={ETypoVariant.SUB_TITLE_16}>
            {description}
          </Typo>
        </div>
        <div className="-mb-1 w-1/2 lg:mb-0 lg:w-full">{select}</div>
      </div>
      {!isEmpty(virtualTours) && (
        <div className="virtualToursBox scroll hidden max-h-650 grid-cols-1 gap-6 overflow-y-auto lg:grid lg:max-h-full lg:gap-3">
          {virtualTours?.map(
            ({
              id,
              thumbnail,
              url,
              maxSize = "",
              minSize = "",
              unitType,
              name,
              project,
            }) => {
              return (
                <VirtualCardLDP
                  key={id}
                  onClick={() => {
                    openModal();
                    setIframe(url);
                  }}
                  className="group cursor-pointer"
                  thumbnailClass={thumbnailClass}
                  data={{
                    location: project?.location?.name || "",
                    sqft: `${minSize} - ${maxSize} spf`,
                    name: project?.name || name || "",
                    types: unitType?.title || "",
                    url: `${Slug.PROJECTS}/${project?.slug || ""}`,
                    urls: thumbnail,
                  }}
                />
              );
            }
          )}
        </div>
      )}
      {modal}
    </div>
  );
};

export default ProjectVirtualTourLDPMobile;
