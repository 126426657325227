import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { Icon } from "~/components/atoms/Icons";
import Media from "~/components/atoms/Media";
import Typo from "~/components/atoms/Typo";
import ModalMediaGallery from "~/components/organisms/ModalMediaGallery";
import { TMedia } from "~/entities/media";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface MasonryGalleryProps {
  project: IProject;
  medias?: TMedia[];
}

// Existing pattern logic
const getPatternForRows = () => {
  const patterns = [
    // [1, 1, 1, 1], // four small images
    [1, 1, 1, 1], // four small
  ];
  return patterns[Math.floor(Math.random() * patterns.length)];
};

const MasonryGalleryLDP: React.FC<MasonryGalleryProps> = ({
  project,
  medias = project?.medias || [],
}) => {
  const [windowWidth, setWindowWidth] = useState<number | null>(null); // Initialize as null
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [indexSlides, setIndexSlides] = useState<number>(0);
  const [mediaWithStyles, setMediaWithStyles] = useState<
    (TMedia & { colSpan: number; rowSpan?: number })[]
  >([]);
  const [collapse, setCollapse] = useState<boolean>(true);
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    handleResize(); // Set initial width
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windowWidth === null) return;

    let totalCols = windowWidth < 864 ? 2 : 4;
    const updatedMediaWithStyles: (TMedia & {
      colSpan: number;
      rowSpan?: number;
    })[] = [];

    const mediaItems = medias || project.medias;
    // Special cases for 1, 2, or 3 media items
    if (mediaItems.length === 1) {
      totalCols = 1;
      updatedMediaWithStyles.push({
        ...mediaItems[0],
        colSpan: 1, // Full width
        rowSpan: 1,
      });
    } else if (mediaItems.length === 2) {
      totalCols = 2;
      mediaItems.forEach((item) => {
        updatedMediaWithStyles.push({
          ...item,
          colSpan: 1, // Half width
          rowSpan: 1,
        });
      });
    } else if (mediaItems.length === 3) {
      totalCols = 2;
      updatedMediaWithStyles.push({
        ...mediaItems[0],
        colSpan: 1, // Half width
        rowSpan: 1,
      });
      updatedMediaWithStyles.push({
        ...mediaItems[1],
        colSpan: 1, // Half width
        rowSpan: 1,
      });
      updatedMediaWithStyles.push({
        ...mediaItems[2],
        colSpan: 2, // Full width for the last item
        rowSpan: 2,
      });
    } else if (mediaItems.length === 4) {
      totalCols = 2;
      mediaItems.forEach((item) => {
        updatedMediaWithStyles.push({
          ...item,
          colSpan: 1, // Half width
          rowSpan: 1,
        });
      });
    } else {
      // Use random patterns for more than 3 media items
      let index = 0;
      while (index < mediaItems.length) {
        const pattern = getPatternForRows();
        let remainingCols = totalCols;
        for (const colSpan of pattern) {
          if (remainingCols <= 0) break;
          if (index < mediaItems.length) {
            const item = mediaItems[index];
            updatedMediaWithStyles.push({
              ...item,
              colSpan,
              rowSpan: colSpan === 2 ? 2 : 1, // large images span 2 rows
            });
            index++;
            remainingCols -= colSpan;
          }
        }
        if (remainingCols > 0) {
          // Ensure to fill the remaining columns
          while (remainingCols > 0 && index < mediaItems.length) {
            updatedMediaWithStyles.push({
              ...mediaItems[index],
              colSpan: 2,
              rowSpan: 2,
            });
            index++;
            remainingCols--;
          }
        }
      }
    }

    setMediaWithStyles(updatedMediaWithStyles);
  }, [windowWidth, project.medias]);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setIndexSlides(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const visibleItems = useMemo(() => {
    const totalItems = isMobile ? 8 : 12;
    return collapse ? totalItems : mediaWithStyles.length;
  }, [isMobile, collapse, mediaWithStyles]);

  return (
    <>
      <div
        className={cn(
          "grid grid-cols-4 gap-6 lg:gap-4 md:grid-cols-2",
          project?.medias?.length < 5 && `grid-cols-${project?.medias?.length}`
        )}
      >
        {mediaWithStyles.slice(0, visibleItems).map((item, index) => (
          <button
            onClick={() => handleOpenModal(index)}
            key={index}
            className={`first:col-span-full col-span-${item.colSpan} row-span-${item.rowSpan} overflow-hidden`}
          >
            <div className="aspect-h-9 aspect-w-16">
              <Media
                onPlay={() => handleOpenModal(index)}
                media={item}
                className="h-full w-full bg-backgroundImage object-cover"
              />
              {item.colSpan}
            </div>
          </button>
        ))}
      </div>
      {mediaWithStyles.length > (isMobile ? 8 : 12) && (
        <div className="flex w-full items-center justify-center">
          <button
            type="button"
            onClick={() => setCollapse(!collapse)}
            className="flex cursor-pointer items-center justify-center gap-1 hover:underline"
          >
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.BODY_TITLE_14}
              className="font-bold"
            >
              {collapse ? t("view_more") : t("view_less")}
            </Typo>
            <Icon
              TypeIcon={EIcon.ChevronDownIcon}
              height={20}
              width={20}
              className={cn(
                "fill-white60 transition-all duration-300",
                !collapse && "rotate-180"
              )}
            />
          </button>
        </div>
      )}
      {isOpen && (
        <ModalMediaGallery
          medias={project?.medias}
          closeModal={handleCloseModal}
          isOpen={isOpen}
          indexSlide={indexSlides}
        />
      )}
    </>
  );
};

export default MasonryGalleryLDP;
