import dayjs from "dayjs";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import CleanedContent from "~/components/atoms/CleanedContent";
import CardProjectDetail from "~/components/molecules/CardProjectDetail";
import Section from "~/components/templates/SectionProject";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";
import { TENURE_ITEM } from "~/utilities/constants/tenureItems";

export interface SectionProjectDetailsProps {
  className?: string;
  id: string;
  title: string;
  titleHtml?: string;
  descriptionHtml?: string;
  project: IProject;
  styleRoot?: any;
}

export type Ref = HTMLDivElement;

const SectionProjectDetailsLDP = forwardRef<Ref, SectionProjectDetailsProps>(
  (
    {
      className = "",
      id,
      title,
      project,
      styleRoot,
      titleHtml = project?.name,
      descriptionHtml = project.description,
    },
    ref
  ) => {
    const { t } = useTranslation();
    if (!project) return null;

    return (
      <Section
        ref={ref}
        id={id}
        className={cn(
          "flex flex-col gap-12 py-20 lg:gap-12 lg:py-7",
          className
        )}
        style={styleRoot}
      >
        <div className="grid grid-cols-2 gap-12 px-0 lg:grid-cols-1">
          <CleanedContent
            className="!text-header-40 font-bold text-color  lg:!text-header-32"
            htmlContent={titleHtml}
          ></CleanedContent>
          <CleanedContent
            htmlContent={descriptionHtml}
            className="h-full w-full text-body-title-16 text-color"
          />
          <div className="col-span-2 col-start-2 lg:col-span-1 lg:col-start-1">
            <CardProjectDetail
              className="py-0 text-color lg:gap-6 lg:px-0  lg:py-0 [&_.text-projectHeadingText]:text-color"
              projectName={t(title)}
              projectDescription={{
                location: project?.location?.name,
                address: project.address,
                developer: project.developer?.name,
                totalUnits:
                  project?.manualTotalUnitsCount > 0
                    ? project?.manualTotalUnitsCount.toString()
                    : project?.totalUnits > 0
                    ? project?.totalUnits.toString()
                    : t("not_available.units"),
                tenure: TENURE_ITEM[project.tenure]?.name,
                expectedTop: project?.expectedTop
                  ? dayjs(project?.expectedTop).format("DD MMMM YYYY")
                  : t("not_available.top"),
                projectCategory: project.category?.name,
                googleMapUrl: "",
              }}
            />
          </div>
        </div>
      </Section>
    );
  }
);

SectionProjectDetailsLDP.displayName = "SectionProjectDetailsLDP";

export default SectionProjectDetailsLDP;
